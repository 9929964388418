export default [
  {
    title: 'Support',
    icon: 'life-ring',
    route: 'Tickets',
    // children: [
    //   {
    //     title: 'Offers',
    //     route: 'Offers'
    //   },
    //   {
    //     title: 'OutgoingInvoices',
    //     route: 'OutgoingInvoices'
    //   }
    // ]
  },
]

export default [
  {
    title: 'HumanResources',
    icon: 'user-tie',
    children: [
      {
        title: 'Employees',
        route: 'Employees',
        resource: 'Employee',
        action: 'manage',
      },
      {
        title: 'Salaries',
        route: 'Salaries'
      },
      {
        title: 'Holidays',
        route: 'Holidays'
      },
      {
        title: 'SocialSecurityContributions',
        route: 'SocialSecurityContributions',
        resource: 'SocialSecurityContributions',
        action: 'manage',
      },
      {
        title: 'Movements',
        route: 'Movements',
        resource: 'Movements',
        action: 'manage',
      }
    ]
  },
]

import { computed } from '@vue/composition-api'
import store from '@/store'

export default function useAppConfig () {
  // ------------------------------------------------
  // isNavMenuHidden
  // ------------------------------------------------
  const isNavMenuHidden = computed({
    get: () => store.state.app.isNavMenuHidden,
    set: val => {
      store.commit('app/UPDATE_NAV_MENU_HIDDEN', val)
    },
  })

  const appName = store.state.app.appName

  return {
    isNavMenuHidden,
    appName
  }
}

import accounting from './accounting'
import contacts from './contacts'
import dashboard from './dashboard'
import indicators from './indicators'
import others from './others'
import purchases from './purchases'
import sales from './sales'
import support from './support'
import warehouse from './warehouse'
import workflows from './workflows'
import humanResources from './humanResources'

export default [
  ...dashboard,
  ...contacts,
  ...indicators,
  ...warehouse,
  ...others,
  ...purchases,
  ...sales,
  ...workflows,
  ...accounting,
  ...humanResources,
  ...support,
]

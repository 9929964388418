export default [
  {
    title: 'Home',
    icon: 'home',
    route: 'home',


    // title: 'Dashboards',
    // icon: 'HomeIcon',
    // tag: '2',
    // tagVariant: 'light-warning',
    // children: [
    //   {
    //     title: 'eCommerce',
    //     route: 'dashboard-ecommerce',
    //   },
    //   {
    //     title: 'Analytics',
    //     route: 'dashboard-analytics',
    //   },
    // ],
  },
]

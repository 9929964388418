export default [
  {
    title: 'Accounting',
    icon: 'chart-line',
    resource: 'CustomerReceivable',
    action: 'manage',
    children: [
      {
        title: 'BankingFlows',
        route: 'BankingFlows',
        resource: 'BankingFlows',
        action: 'manage',
      },
      {
        title: 'CustomerReceivable',
        route: 'CustomerReceivable',
        resource: 'CustomerReceivable',
        action: 'manage',
      },
      {
        title: 'SupplierReceivable',
        route: 'SupplierReceivable',
        resource: 'SupplierReceivable',
        action: 'manage',
      }
    ]
  },
]
